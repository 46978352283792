export const messages = {
  /*common*/
  WebUrl: "https://pro.hazsim.com",
  socketURL: "https://pro.hazsim.com:2053",
  // WebUrl:"http://localhost:8000",
  GraphImageUrl: "https://hazsim-v3-images.s3.us-east-2.amazonaws.com/training-tag/",
  Title: "HazSim",
  NotFound: 404,
  LinkExpired: 1018,
  Profile: "Profile",
  EditAlarm: "Edit Alarm",
  BasicDetails: "Basic Details",
  Alarm: "Alarm",
  Range: "Range",
  Preview: "Preview",
  deleteSuccess: "Question set deleted successfully",
  alarmSaveSuccessfully: "Alarm saved successfully",
  meterDeleteSuccessfully: "Meter deleted successfully",
  sensorDeleteSuccessfully: "Sensor deleted successfully",
  defaultStudentNameDeleteSuccessfully: "Student deleted successfully",
  uploadFileInitialState: { actualName: "man.png", serverName: "man.png" },
  ExpiryLink: "Reset password link has been expired.",
  ResetPasswordFail: "Reset Password failed",
  ResetPasswordSuccessfully: "Password updated successfully",
  organizationSelect: "Select Organization",
  home: "Home",
  Support: "Support",
  Settings: "Settings",
  Manuals: "Manuals",
  Issue: "Issue : ",
  submit: "Submit",
  Resolution: "Resolution : ",
  ReferenceLink: "Reference Link",
  TroubleshootingInformation: "Troubleshooting Information",
  Videos: "Videos",
  SupportContact: "Support Contact Info",
  CityCreated: "New city created",
  StateCreated: "New state created",
  CountryCreated: "New country created",
  Investor: "(Investor)",
  signUp: "Sign Up",
  signIn: "Sign In",
  ForgotPassword: "Forgot Password ?",
  EMAIL: "EMAIL",
  EmailTitleCase: "Email",
  PASSWORD: "PASSWORD",
  NEW_PASSWORD: "NEW PASSWORD",
  CONFIRM_NEW_PASSWORD: "CONFIRM NEW PASSWORD",
  HOW_MAY_I_HELP_YOU: "HOW MAY I HELP YOU?",
  LOGIN: "LOGIN",
  DONT_HAVE_AN_ACCOUNT: "Don't have an account ?",
  SUBMIT: "SUBMIT",
  scSecretKey: "ha2O7APJjaQIB4eV1hNSbXwrm9cUfoztsHFWvEgZ6qL1lDyGRnYdkMTKx0uA41",
  invalidEmailPassword: "Please enter valid email and password",
  momentDateFormat: "MM-DD-YYYY", //for Fri Feb 05 2021 use "ddd MMM DD YYYY"
  momentDateWithTime: "MM-DD-YYYY hh:mm A", //for Fri Feb 05 2021 use "ddd MMM DD YYYY"
  momentDateWithTimeFormat: "YYYY-MM-DDTHH:mm",
  loginSuccess: "Login Successful",
  logoutSuccess: "Logout Successful",
  ToastError: "error",
  ToastSuccess: "success",
  contactError: "Something went wrong, please try again later.",
  somethingWentWrong: "Something went wrong",
  contactSuccess: "Thanks for contacting us.",
  organizationCreated: "Organization created successfully.",
  organizationUpdated: "Organization updated successfully.",
  supportContactInfoUpdated: "Support contact information updated successfully",
  deactivate: "Deactivate",
  delete: "Delete",
  deleteSensorMessage: "Do you want to delete",
  deleteMeterMessage: "Do you want to delete",
  deleteQuestionSetMessage: "Do you want to delete",
  Activate: "Activate",
  suspend: "Suspend",
  cancel: "Cancel",
  Save: "Save",
  SaveAndExit: "Save & Exit",
  Update: "Update",
  Edit: "Edit",
  Devices: "Devices",
  Assessments: "Assessments",
  deactivateOrganizationMessage: "Do you want to deactivate",
  activateOrganizationMessage: "Do you want to activate",
  suspendUserMessage: "Do you want to suspend",
  deleteQuestionMessage: "Do you want to delete question <question> ?",
  cancelQuestionSetMessage: "Do you want to leave without saving the changes ?",
  deleteMeterSensorMessage: "Do you want to delete sensor <sensorName> ?",
  areYouSure: "Are you sure?",
  MailSent: "Mail sent successfully",
  cancelFormChanges: "Are you sure you want to cancel without saving the changes?",
  UserAlreadyExist: "User with same email already exist, try another email",
  StudentAlreadyExist: "Student with same name already exist, try another name",
  UserAlreadyExistAnotherOrg: "User with same email already exist in another organization, try another email",
  organizationEmailAlreadyExist: "Organization with same email already exist, try another email",
  organizationNameAlreadyExist: "Organization with same name already exist, try another name",
  orgContactSameOrgExist: "User with contact email already exists for same organization",
  orgContactOtherOrgExist: "User with contact email already exists for another organization.",
  SensorAlreadyExist: "Sensor already exist",
  MeterAlreadyExist: "Meter already exist",
  AddMeterPermissionDenied: "Meter creation is disabled for your organization, contact super admin",
  userAccountUpdated: "User account updated successfully",
  UserProfileUpdated: "User profile updated successfully",
  SensorAdded: "Sensor added successfully",
  SensorUpdated: "Sensor updated successfully",
  MeterAdded: "Meter added successfully",
  MeterUpdated: "Meter updated successfully",
  ServerNotResponding: "Something went wrong, please try later",
  ConfirmEmail: "Confirm Email",
  AgreementAlreadyExist: "Agreement already exist",
  AgreementVersionAlreadyExist: "Agreement version already exists",
  AddAgreementPermissionDenied: "Agreement creation is disabled for your organization and instructor, contact super admin",
  AgreementAdded: "Agreement added successfully",
  AgreementUpdated: "Agreement updated successfully",
  EffectiveDate: "Effective date/Time must be greater than uploaded date",
  //Grid
  GridWidth200: 200,
  GridWidth80: 80,
  GridWidth100: 100,
  GridWidth250: 250,
  GridWidth160: 160,
  GridWidth120: 120,
  GridRowheight: 30,
  GridHeaderheight: 60,
  GridRowCountOptions: [
    { id: "0", value: 10, label: "10" },
    { id: "1", value: 25, label: "25" },
    { id: "2", value: 50, label: "50" },
    { id: "3", value: 100, label: "100" },
  ],
  typeList: [
    { id: 1, value: 1, label: "Tablet" },
    { id: 2, value: 2, label: "Mobile" },
  ],
  ValidProfile: "Invaild file type. Only image file format is allowed.",
  ProfileUploadErr: "Profile image not updated, please try again",

  // validation regex

  OnlyNumeric: /^[+-]?(\d+(\.\d+)?)$/,
  OnlyAlphaNumeric: /^\d*[a-zA-Z][a-zA-Z0-9\s]*$/,
  OnlyAlphaNumericWithoutSpace: /^\d*[a-zA-Z][a-zA-Z0-9]*$/,
  RegexAlphaNumericSpecialChar: /^[a-zA-Z0-9_.-;=?@.`!,%$^#,[\]{}a-zA-Z0-9\-_.,@#%~&()*_=+?;:'"<>|\\\s]*$/g,
  OnlyAlphaWithDot: /^\d*[a-zA-Z][a-zA-Z.]*$/,
  RegexAllowForPhoneNumber: /^[0-9(+][0-9()-]{7,29}$/,
  RegexSensorPrecision: /^[0-9]$/,
  RegexPassword: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,}$/g,
  RegexWebsite: /^(http:\/\/|https:\/\/)?([a-z0-9][a-z0-9]*\.)?([a-z0-9][a-z0-9-]*\.)+[a-z0-9]{2,3}[a-z0-9-]{0,3}$/,
  RegexForEmail: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  searchContentRegex: /[.*\\/()?:+=![\]&^\-{}|$]/g,
  searchContentReplaceRegex: "\\$&",

  // variable
  AddOrganization: "Add Organization",
  EditOrganization: "Edit Organization",
  BasicInfo: "Basic Info",
  Organization: "Organization",
  Industry: "Industry",
  Website: "Website",
  ExpiryDate: "Expiry Date",
  Address: "Address",
  Street: "Street",
  Country: "Country",
  State: "State",
  City: "City",
  ZipCode: "Zip Code/Postal Code",
  Contact: "Contact",
  Name: "Name",
  MobileNumber: "Mobile Number",
  OfficePhone: "Office Phone",
  Type: "Type",
  MacId: "Mac ID",
  AddUser: "Add User",
  EditUser: "Edit User",
  AddSensor: "Add Sensor",
  EditSensor: "Edit Sensor",
  CopySensor: "Copy Sensor",
  Meters: "Meters",
  AssignMeters: "Assign Meters",
  AssignMeterCategory: "Assign Meter Categories",
  skinType: [
    {
      id: "1",
      name: "Altair"
    },
    {
      id: "2",
      name: "HazSim Digital"
    },
    {
      id: "3",
      name: "Multirae"
    },
    {
      id: "4",
      name: "Drager X-am 8000"
    },
    {
      id: "5",
      name: "Drager X-am 2800"
    },
    {
      id: "6",
      name: "Drager X-am 3500"
    }
  ],
  category: [
    {
      id: 0,
      value: null,
      label: "Select Category"
    },
    {
      id: 1,
      value: 0,
      label: "Multi-Gas"
    },
    {
      id: 2,
      value: 1,
      label: "RAD"
    },
    {
      id: 3,
      value: 2,
      label: "WMD"
    },
  ],
  categoryValues: ["Multi-Gas", "RAD", "WMD"],
  role: [
    {
      id: "2",
      name: "Admin"
    },
    {
      id: "3",
      name: "Instructor"
    },
    // {
    //     "id": "4",
    //     "name": "Student"
    // }
  ],
  AllRole: [
    {
      id: "1",
      name: "Super Admin"
    },
    {
      id: "2",
      name: "Admin"
    },
    {
      id: "3",
      name: "Instructor"
    },
    // {
    //     "id": "4",
    //     "name": "Student"
    // }
  ],
  sensorTypeOptions: [
    { label: "Standard", value: 1 },
    { label: "Bar", value: 2 },
    { label: "Exponential", value: 3 },
    { label: "Calculated", value: 4 }
  ],
  sensorTypes: {
    STANDARD: 1,
    BAR: 2,
    EXPONENTIAL: 3,
    CALCULATED: 4,
  },
  sensorNoOfUnitsOptions: [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 }
  ],
  RoleLabel: "Role",
  AddMeter: "Add Meter",
  EditMeter: "Edit Meter",
  CopyMeter: "Copy Meter",

  //validation message
  InvalidMacId: "Enter valid Mac Id",
  InvalidName: "Enter valid name(alpha-numeric value, maximum 255 characters)",
  InvalidOrgName: "Enter valid organization name(alpha-numeric value, maximum 255 characters, Allowed Special characters)",
  InvalidIndustryName: "Enter valid Industry name(alpha-numeric value, maximum 255 characters)",
  InvalidWebsiteName: "Enter valid website.",
  InvalidExpiryDate: "Enter valid Expiry date (Expiry date can not be less than current date)",
  InvalidStreetName: "Enter valid Street Name(alpha-numeric value, maximum 500 characters, Allowed Special characters)",
  InvalidZipCode: "Enter valid Zipcode / Postal code(alpha-numeric value, maximum 30 characters)",
  InvalidEmail: "Enter valid Email id(alpha-numeric value, maximum 255 characters)",
  InvalidConfirmEmail: "Email and confirm email doesn't matched.",
  InvalidMobileNumber: "Enter valid mobile number(8-30 numeric characters)",
  InvalidPhoneNumber: "Enter valid Phone number(8-30 numeric characters)",
  InvalidMacDulicateId: "Mac Id already exists",
  InvalidSensorName: "Enter valid sensor name(alpha-numeric value, maximum 500 characters, Allowed Special characters)",
  InvalidMeterName: "Enter valid meter name(alpha-numeric value, maximum 250 characters, Allowed Special characters)",
  InvalidSensorLabel: "Enter valid sensor label(alpha-numeric value, maximum 500 characters, Allowed Special characters)",
  InvalidSensorPrecision: "Enter valid sensor precision (Allowed positive number)",
  InvalidSensorBounce: "Enter valid sensor bounce (Allowed positive number)",
  InvalidSensorBounceInterval: "Enter valid sensor bounce interval (Allowed positive number)",
  InvalidSensorNormVal: "Norm value should be greater than or equal to the min value.",
  InvalidSensorNormValBar: "Norm value should be greater than or equal to 0.",
  InvalidSensorNormVal1: "Norm value should be greater than or equal to the min value and greater than low alarm.",
  InvalidSensorNormVal2: "Norm value should be greater than or equal to the min value and less than alarm 2 value",
  InvalidSensorNormVal2ForBar: "Norm value should be less than alarm 2 value",
  InvalidSensorNormVal1ForBar: "Norm value should be less than alarm 1 value",
  InvalidSensorIncrement: "Enter valid sensor increment (Allowed positive number)",
  InvalidSensorLumpIncrement: "Enter valid jump increment (Allowed positive number)",
  InvalidSensorMinVal: "Enter valid min. value (Allowed valid integer)",
  InvalidSensorInterVal: "Enter valid alarm 1 value (Alarm 1 value should be between norm value and alarm 2)",
  InvalidSensorAlarm2Val1: "Enter valid alarm 2 value (Alarm 2 value should be greater than norm value and alarm 1 value)",
  InvalidSensorAlarm2Val2: "Enter valid alarm 2 value (Alarm 2 value should be between norm value and less than max value)",
  InvalidSensorLowLowAlarm: "Enter valid low low alarm value (Low low alarm value should be greater than min value)",
  InvalidSensorLowLowAlarmNorm: "Enter valid low low alarm value (Low low alarm value should be greater than or equals to min value and less than norm value)",
  InvalidSensorLowLowAlarmLowA: "Enter valid low low alarm value (Low low alarm value should be greater than or equals to min value and less than low alarm)",
  InvalidSensorLowAlarm: "Enter valid low alarm value (Low alarm value should be between min value and norm value)",
  InvalidSensorLowAlarmNorm: "Enter valid low alarm value (Low alarm value should be between min value and norm value)",
  InvalidSensorLowAlarmNormBar: "Enter valid low alarm value (Low alarm value should be between 0 and norm value)",
  InvalidSensorLowAlarmLLA: "Enter valid low alarm value (Low alarm value should be between low low alarm value and norm value)",
  InvalidSensorMaxVal: "Enter valid max value (Max Value can not be less than norm value)",
  InvalidSensorNumberOfBars: "Enter valid max value (Max Value can not be less than 1 or greater than 6, and less than norm value)",
  InvalidSensorUnits: (name) => `"${name}" unit is already selected`,
  InvalidSensorSelect: (name) => `"${name}" sensor is already selected`,
  CalculatedNotAvailable: "You need to add at least one sensor, before you can add calculated sensor",
  InvalidSensorValue: "Enter valid sensor value (Allowed positive number)",
  inValidDate: "Enter valid date of birth.",
  invalidMessage: "Enter valid message(alpha-numeric value, maximum 10000 characters, Allowed Special characters)",
  addSensorLabel: "Label",
  addSensorType: "Type",
  addSensorNoOfUnits: "No. of units",
  addSensorUnits: "Unit",
  addSensorPrecision: "Precision",
  addSensorBounce: "Bounce",
  addSensorBounceInterval: "Bounce interval",
  addSensorNormValue: "Norm Value",
  addSensorIncrement: "Increment",
  addSensorLumpIncrement: "Jump Increment",
  addSensorMinValue: "Min. Value",
  addSensorAlarmLowLowValue: "Low Low Alarm",
  addSensorAlarmLowValue: "Low Alarm",
  addSensorInterValue: "Alarm 1",
  addSensorAlarm2: "Alarm 2",
  addSensorMaxValue: "Max. Value",
  numberOfBars: "No. of bars",
  addSensorLinkedSensor: "Linked sensor",
  addSensorOperation: "Operation",
  addSensorValue: "Value",
  SensorAddLimit: "You can add only upto seven sensors",
  //Question set
  AddQuestionSet: "Add Question Set",
  EditQuestionSet: "Edit Question Set",
  CopyQuestionSet: "Copy Question Set",
  QuestionSetName: "Question Set Name",
  Questions: "Questions",
  Question: "Question",
  RightAnswer: "Right Answer",
  WrongAnswer: "Wrong Answer",
  InvalidQuestionName: "Enter valid question set name (alpha-numeric value, maximum 500 characters, Allowed Special characters)",
  QuestionSetAlreadyExist: "Question set already exist",
  QuestionSetAdded: "Question set added successfully",
  QuestionSetUpdated: "Question set updated successfully",
  StaticQuestion: "Write question in the Question field",
  DuplicateTablet: "You can only assign one tablet to the organization",
  LogViewer: "Log Viewer",
  TrainingId: "Training Id",
  TrainingName: "Training Name",
  InstructorName: "Instructor Name",
  From: "From",
  StartDate: "Start Date",
  Duration: "Duration (hrs)",
  To: "To",
  Student: "Student",
  Report: "Report",
  Session: "Session",
  //meter
  SelectSkin: "Select Skin",
  Category: "Category",
  MeterName: "Meter Name",
  Sensors: "Sensors",
  TrainingLogAction: {
    trainingStarted: 1,
    sensorReadingSent: 2,
    buzzSent: 3,
    skinChanged: 4,
    meterChanged: 5,
    tagAdded: 6,
    questionSent: 7,
    answerSent: 8,
    alarm: 9,
    interAlarm: 10
  },
  TrainingLogDeleteSucc: "Training log image deleted successfully",
  DeleteImage: "Delete Image",
  SelectAction: "Select the action you want to perform",
  ConfirmDeleteAction: "Do you want to delete the image?",
  TagImage: "Tag Image",
  ViewImage: "View Image",
  graphData: [
    {
      name: "Oxygen",
      label: "O2",
      type: 2,
      minValue: 17,
      maxValue: 26,
      units: [
        {
          name: "ppb",
          id: "615557e0f494412ceb7267ea"
        }
      ],
      trainingLogs: [
        {
          id: "dfsgfdg-dfgfd-v",
          action: 2,
          reading: 4
        },
        {
          id: "dfsgfdg-dfgfd-3",
          action: 2,
          reading: 5
        },
        {
          id: "dfsgfdg-dfgfd-5",
          action: 6,
          tag: 5
        },
        {
          id: "dfsgfdg-dfgfd-6",
          action: 7,
          question: "what is standard reading of o2",
          answers: ["21", "33"]
        },
        {
          id: "dfsgfdg-dfgfd-7",
          action: 2,
          reading: 21
        },
        {
          id: "dfsgfdg-dfgfd-8",
          action: 2,
          reading: 21.6
        },
        {
          id: "dfsgfdg-dfgfd-9",
          action: 8,
          question: "what is standard reading of o2",
          givenAnswer: "33",
          rightAnswer: "21",
          isGivenAnswerCorrect: false
        }
      ],
    },
    {
      name: "Carbon Dioxide",
      label: "co2",
      type: 2,
      minValue: 1,
      maxValue: 5,
      units: [
        {
          name: "ppb",
          id: "615557e0f494412ceb7267ea",
        }
      ],
      trainingLogs: [
        {
          id: "tfsgfdg-dfgfd-v",
          action: 2,
          reading: 4
        },
        {
          id: "tfsgfdg-dfgfd-3",
          action: 2,
          reading: 5
        },
        {
          id: "tfsgfdg-dfgfd-5",
          action: 6,
          tag: 5
        },
        {
          id: "tfsgfdg-dfgfd-6",
          action: 7,
          question: "what is standard reading of co2",
          answers: ["2", "5"]
        },
        {
          id: "tfsgfdg-dfgfd-7",
          action: 2,
          reading: 21
        },
        {
          id: "tfsgfdg-dfgfd-8",
          action: 2,
          reading: 21.6
        },
        {
          id: "tfsgfdg-dfgfd-9",
          action: 8,
          question: "what is standard reading of co2",
          givenAnswer: "2",
          rightAnswer: "5",
          isGivenAnswerCorrect: true
        }
      ],
    }
  ],
  combinedView: "Combined View",
  includeGraphInReport: "Include Graph In Report",
  trainingReportOptions: [
    { id: 1, label: "All", value: "All" },
    { id: 2, label: "Selected students", value: "Selected-user" }
  ],
  defaultStudentName: "Default Student Names",
  acceptedAgreements:"",
  meterPreviewDefaults: {
    noName: "Name not available",
    noSkin: "Preview not available"
  }
};
